/* eslint-disable react/prop-types */
import React, { PureComponent } from 'react';

import scroll from 'app/shared/utils/smoothScroll';
import { getLinks } from 'app/services/api/search';
import information from 'app/shared/constants/information';
import LayoutBootstrap from '../../layout-bootstrap/LayoutBootstrap';
import HeadHome from './HeadHome';
import Queries from './Queries';
import Search from './Search';
import Banner from './Banner';
import Links from './Links';
import Info from './Info';

class Home extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      links: information,
    };
  }

  componentDidMount() {
    const { getLocation } = this.props;
    getLocation();
    (async () => {
      const { data: links } = await getLinks();
      this.setState({ links });
    })();
  }

  eventScroll = () => {
    scroll(0, 0);
  };

  render() {
    const {
      dataSearch: {
        insurance,
        city,
        autocomplete,
        accepts_in_person,
        accepts_telemedicine,
      },
      getSearchResult,
      generateLink,
      setDataSearch,
      userLocation,
      searching,
      login,
    } = this.props;
    const { links } = this.state;

    return (
      <>
        <LayoutBootstrap login={login}>
          <HeadHome />
          <Search
            getSearchResult={getSearchResult}
            setDataSearch={setDataSearch}
            autocompleteValue={autocomplete}
            userLocation={userLocation}
            insuranceValue={insurance}
            cityValue={city}
            acceptsInPersonValue={accepts_in_person}
            acceptsTelemedicineValue={accepts_telemedicine}
            searching={searching}
            describe
          />
          <Queries />
          <Info eventScroll={this.eventScroll} />
          <Banner />
          <Links generateLink={generateLink} information={links} />
        </LayoutBootstrap>
      </>
    );
  }
}

export default Home;
