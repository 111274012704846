// External
import React from 'react';
import RowBoot from 'react-bootstrap/Row';
import ColBoot from 'react-bootstrap/Col';
import ContainerBoot from 'react-bootstrap/Container';

// Internal
import descriptions from 'app/shared/constants/descriptions';

import css from './Info.module.scss';

const Info = ({ eventScroll }) => (
  <ContainerBoot className={css.info}>
    <section>
      <RowBoot>
        {descriptions.map((item, index) => (
          <ColBoot key={index.toString()} className={css['info-content']} lg={4} md={4} sm={12}>
            <div className={css['card-custom']}>
              <img alt={item.image.alt} src={item.image.path} />
            </div>
            <h3>{item.title}</h3>
            <p>{item.describe}</p>
          </ColBoot>
        ))}
      </RowBoot>
      <RowBoot>
        <ColBoot className={css.center}>
          <button onClick={eventScroll}>
            Buscar médicos perto de mim
          </button>
        </ColBoot>
      </RowBoot>
    </section>
  </ContainerBoot>
);

export default Info;
