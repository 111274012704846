// External
import React from 'react';
import RowBoot from 'react-bootstrap/Row';
import ColBoot from 'react-bootstrap/Col';
import ContainerBoot from 'react-bootstrap/Container';

// Internal
import WebPicture from 'app/components/WebPicture';
import css from './Banner.module.scss';

const goToProfile = () => {
  window.location.href = '/criar-perfil-doutor';
};

const Banner = () => (
  <RowBoot className={css.banner}>
    <ContainerBoot>
      <img
        className={`${css['banner-avatar']} ${css.left}`}
        alt="check"
        src="static/images/nurse.png"
      />
      <img
        className={`${css['banner-avatar']} ${css.mobile}`}
        alt="check"
        src="static/images/nurse.png"
      />
      <ColBoot className={css['banner-content']}>
        <RowBoot>
          <ColBoot lg={12} md={12} sm={12}>
            <h2>Você é um profissional da saúde?</h2>
          </ColBoot>
        </RowBoot>
        <RowBoot>
          <ColBoot className={css['create-proflie']} lg={6} md={12} sm={12}>
            <h3>
              Crie seu perfil no
              <b> AgendarConsulta</b>
              .com
            </h3>
            <button type="button" onClick={goToProfile}>
              Criar perfil médico
            </button>
          </ColBoot>
          <ColBoot className={css.advantages} lg={6} md={12} sm={12}>
            <RowBoot>
              <ColBoot className={css.check} xs={1} sm={1} md={1} lg={1}>
                <img alt="check" src="static/images/check.png" />
              </ColBoot>
              <ColBoot className={css.text} xs={10} sm={10} md={10} lg={10}>
                <p>Atraia e fidelize novos pacientes</p>
              </ColBoot>
            </RowBoot>
            <RowBoot>
              <ColBoot className={css.check} xs={1} sm={1} md={1} lg={1}>
                <img alt="check" src="static/images/check.png" />
              </ColBoot>
              <ColBoot className={css.text} xs={10} sm={10} md={10} lg={10}>
                <p>Melhore sua presença online</p>
              </ColBoot>
            </RowBoot>
            <RowBoot>
              <ColBoot className={css.check} xs={1} sm={1} md={1} lg={1}>
                <img alt="check" src="static/images/check.png" />
              </ColBoot>
              <ColBoot className={css.text} xs={10} sm={10} md={10} lg={10}>
                <p>
                  Ofereça aos seus pacientes uma experiência de agendamento
                  incrível
                </p>
              </ColBoot>
            </RowBoot>
            <RowBoot className={css['content-mobile']}>
              <button type="button" onClick={goToProfile}>
                Criar perfil médico
              </button>
            </RowBoot>
          </ColBoot>
        </RowBoot>
      </ColBoot>
      <WebPicture
        alt="Imagem ilustrativa de um médico"
        className={`${css['banner-avatar']} ${css.right}`}
        source="static/images/dr.webp"
        fallback={{ src: 'static/images/dr.png', type: 'image/png' }}
      />
    </ContainerBoot>
  </RowBoot>
);

export default Banner;
